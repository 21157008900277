import React, { useState, useEffect } from 'react';
import { withFormik } from 'formik';
import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import { Button, Form, Grid, Icon } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import Actions from 'src/state/root-actions';
import { Banner, InfoMessage } from 'src/components/shared';

import { getErrorMessage } from 'src/utils/form-utils';

interface FormValues {
    newPassword: string;
    error?: string;
}

type field = keyof FormValues;
type Errors = {
    [key in field]?: string;
};

const ResetPasswordForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => setShowPassword(!showPassword);
    const validLengthConstraint = values.newPassword.length >= 8;
    const validUpperConstraint = /[A-Z]/.test(values.newPassword);
    const validNumberConstraint = /[0-9]/.test(values.newPassword);

    const [validLength, setValidLength] = useState(validLengthConstraint);
    const [validNumber, setValidNumber] = useState(validNumberConstraint);
    const [validUpper, setValidUpper] = useState(validUpperConstraint);

    useEffect(() => {
        setValidLength(validLengthConstraint);
        setValidNumber(validNumberConstraint);
        setValidUpper(validUpperConstraint);
    }, [validLengthConstraint, validNumberConstraint, validUpperConstraint]);

    console.log({ values, errors, isValid, dirty });

    return (
        <Form onSubmit={handleSubmit}>
            <input
                type="hidden"
                id="reset-password-code"
                name="confirmationCode"
                value={values.confirmationCode}
                aria-label="Cognito Code (hidden form input)"
            />
            <input
                type="hidden"
                id="reset-password-email"
                name="email"
                value={values.email}
                aria-label="Email (hidden form input)"
            />
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        {errors?.general && <Banner type="ERROR">{errors.general}</Banner>}
                        <Form.Field>
                            <Form.Input
                                icon={
                                    <Icon
                                        link
                                        name={`eye${showPassword ? ' slash outline' : ''}`}
                                        onClick={toggleShowPassword}
                                    />
                                }
                                label={{
                                    children: 'New Password',
                                    htmlFor: 'new-password',
                                    className: values.newPassword ? '' : 'hidden',
                                }}
                                id="new-password"
                                name="newPassword"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="New Password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.newPassword}
                                error={
                                    errors.newPassword && touched.newPassword
                                        ? getErrorMessage(errors.newPassword)
                                        : false
                                }
                            />
                            {validLength ? (
                                <InfoMessage
                                    content="must be at least 8 characters"
                                    type="SUCCESS"
                                />
                            ) : (
                                <InfoMessage content="must be at least 8 characters" />
                            )}

                            {validUpper ? (
                                <InfoMessage
                                    content="contain at least 1 uppercase letter"
                                    type="SUCCESS"
                                />
                            ) : (
                                <InfoMessage content="contain at least 1 uppercase letter" />
                            )}

                            {validNumber ? (
                                <InfoMessage content="contain at least 1 number" type="SUCCESS" />
                            ) : (
                                <InfoMessage content="contain at least 1 number" />
                            )}
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            type="submit"
                            disabled={isSubmitting || !isValid || !dirty}
                            loading={isSubmitting}
                            primary
                            fluid
                        >
                            SAVE
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

const handleSubmit = (formValues, { props, setFieldError, setSubmitting }) => {
    const onFulfill = () => setSubmitting(false);
    const onFailure = (message) => setFieldError('general', message);
    const onSuccess = () => {
        props.dispatch(
            Actions.auth.login.trigger({
                ...formValues,
                onSuccess: () => {
                    navigate(props.redirectPath, {
                        state: { isResetPasswordSuccess: true },
                    });
                },
                onFailure: () => {
                    navigate('/continue', {
                        state: { isResetPasswordSuccess: true },
                    });
                },
            })
        );
    };

    props.dispatch(
        Actions.auth.confirmForgotPassword.trigger({
            ...formValues,
            onFailure,
            onSuccess,
            onFulfill,
        })
    );
};

const validate = (values) => {
    const errors: Errors = {};

    const validLengthConstraint = values.newPassword.length >= 8;
    const validUpperConstraint = /[A-Z]/.test(values.newPassword);
    const validNumberConstraint = /[0-9]/.test(values.newPassword);

    if (
        isEmpty(values.newPassword) ||
        !validLengthConstraint ||
        !validUpperConstraint ||
        !validNumberConstraint
    ) {
        errors.newPassword = 'is not valid';
    }

    return errors;
};

const FormikResetPasswordForm = withFormik({
    handleSubmit,
    validate,
    mapPropsToValues: (props) => {
        const { code } = props;

        return {
            confirmationCode: code || undefined,
            email: props?.email || '',
            newPassword: '',
        };
    },
})(ResetPasswordForm);

export default connect()(FormikResetPasswordForm);
