import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { navigate } from 'gatsby';
import { Grid } from 'semantic-ui-react';

import ResetPasswordForm from 'src/components/auth/ResetPasswordForm';
import SEO from 'src/components/SEO';
import withLocation from 'src/components/withLocation';

import * as authStyles from 'src/styles/auth.module.scss';

const ResetPasswordPage = ({ search: { email, confirmation_code: code, redirectPath } }) => {
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        // if no email, redirect to /continue
        if (isEmpty(email)) {
            navigate('/continue');
        }
    }, []);

    if (isEmpty(email)) {
        return null;
    }

    return (
        <div className={authStyles.authWrapper}>
            <SEO title="Pvolve Reset Password" />
            <Grid centered columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <p>Create a new password for your account associated with {email}.</p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <ResetPasswordForm code={code} email={email} redirectPath={redirectPath} />
        </div>
    );
};

export default withLocation(ResetPasswordPage);
